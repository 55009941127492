import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";
import router from "@/router";

export default {
  // eslint-disable-next-line no-unused-vars
  login({ commit }, { email, password }) {
    try {
      return apiService.post('users/login', { email, password }, { baseURL: config.VUE_APP_API_USERS_URL })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  setScoringFormula({ commit }, { formula }) {
    try {
      return apiService.patch('user-params/updateParams', { formula }, { baseURL: config.VUE_APP_API_USERS_URL })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  setFiat({ commit }, { preferredCurrency }) {
    try {
      return apiService.patch('user-params/updateParams', { currencyCode: preferredCurrency }, { baseURL: config.VUE_APP_API_USERS_URL })
    } catch (error) {
      console.error(error)
    }
  },
  setNotifications(cntx, { emailAlertsEnabled }) {
    try {
      return apiService.patch('user-params/updateParams', { emailAlertsEnabled }, { baseURL: config.VUE_APP_API_USERS_URL })
    } catch (error) {
      console.error(error)
    }
  },
  logout({ commit }) {
    commit('REMOVE_USER_TOKEN')
    commit('REMOVE_USER_ID')
  },
  register({ commit }, { email, password, invitationCode }) {
      try {
          return apiService.post('users/register', { email, password, invitationCode }, { baseURL: config.VUE_APP_API_USERS_URL })
              .then(({ data }) => {
                  commit('SET_USER_TOKEN', data)
                  commit('SET_USER_ID', data) })
      } catch (error) {
          console.error(error)
      }
  },
  resetPassword({ commit }, { email, password, resetToken }) {
    return apiService.post('users/update-password', { email, password, resetToken }, { baseURL: config.VUE_APP_API_USERS_URL })
      .then(({ data }) => {
        commit('SET_USER_TOKEN', data)
        commit('SET_USER_ID', data) })
  },
  getMe({ commit }) {
    return apiService.get(`users/me`, { baseURL: config.VUE_APP_API_USERS_URL })
      .then(({ data }) => {
        commit('SET_USER_DATA', data)})
      .catch(() => {
        return true
      })
  },
  resetToken({ commit }, { refreshToken }) {
    return apiService.get(`users/reset-token/${refreshToken}`, { baseURL: config.VUE_APP_API_USERS_URL })
        .then(({ data }) => {
          if (data && data.token) {
            commit('SET_USER_TOKEN', data)
            commit('SET_USER_REFRESH_TOKEN', data)
            commit('SET_USER_ID', data)
            return true
          } else {
            if (router.currentRoute.name !== 'login') {
              localStorage.setItem('lastRoute', router.currentRoute.name)
            }
            this.logout()
            window.location.assign('/login')
          }
        })
  },
  initToken({ commit }) {
    const token = localStorage.getItem('token')
    if (token) commit('SET_USER_TOKEN', { token })
    return token;
  },
}
