import apiService from '@/utils/api-client'
import analytics from "@/store/modules/analytics";
import config from "@/utils/appConfig";

export default {
    // eslint-disable-next-line no-unused-vars
    createAlert({ commit }, data) {
        const sendedData = data
        try {
            const data = apiService.post('/alert', { ...sendedData }, { baseURL: config.VUE_APP_API_USERS_URL })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertsList({ commit }, { $skip = 0, $limit = 10, monitoring = undefined }) {
        try {
            return apiService.get('/alert', { params: { $skip, $limit, monitoring }, baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertsById({ commit }, id) {
        try {
            return apiService.get(`/alert/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertsByIds({ commit }, id) {
        try {
            return apiService.get(`/alert`, { params: { id }, baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertsByAddress({ commit }, address) {
        try {
            return apiService.get(`/alert/-/address/${address}`)
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAlertDataByAddress({ commit }, { address, alertId }) {
        try {
            return apiService.get(`/alert/${alertId}/address/${address}`)
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getAddressesForAlert({ commit }, id) {
        try {
            return apiService.get(`/alert/${id}/address`, { baseURL: config.VUE_APP_API_USERS_URL })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    updateAlert({ commit }, { id, data }) {
        const { archive, disabled, retrospective, after, title, description, priority, email } = data

        return apiService.patch(`/alert/${id}`, { archive, disabled, retrospective, after, title, description, priority, email }, { baseURL: config.VUE_APP_API_USERS_URL })
    },
    // eslint-disable-next-line no-unused-vars
    addAlertToAddresses({ commit }, { id, addresses }) {
        return apiService.put(`/alert/${id}/address`,  { address: addresses, coin: analytics.state.coinData.key }, { baseURL: config.VUE_APP_API_USERS_URL })
    },
    // eslint-disable-next-line no-unused-vars
    bindAlertToAddresses({ commit }, { id, addresses, coin }) {
        const url = analytics?.state?.currencyList.find(el => el.key === coin).baseUrl

        return apiService.put(`/alert/${id}/address`,  { address: addresses, coin }, { baseURL: url })
    },
    // eslint-disable-next-line no-unused-vars
    deleteAlert({ commit }, id) {
        return apiService.delete(`/alert/${id}`, { baseURL: config.VUE_APP_API_USERS_URL });
    },
    // eslint-disable-next-line no-unused-vars
    deleteAlertToAddressPair({ commit }, { id, address }) {
        return apiService.delete(`/alert/${id}/address`, { data: { address }, baseURL: config.VUE_APP_API_USERS_URL });
    },

    // eslint-disable-next-line no-unused-vars
    deleteAlertByCoin({ commit }, { id, addresses, url }) {
        return apiService.delete(`/alert/${id}/address`, { data: { addresses, alertId: id }, baseURL: url });
    },

    // Notifications

    // eslint-disable-next-line no-unused-vars
    async getAlertStat({ commit }, { alertId }) {
        try {
            return apiService.get(`/alert-notification/alert/-/stats`,{ params: { alertId }, baseURL: config.VUE_APP_API_USERS_URL  })
        } catch (error) {
            console.error(error)
        }
    },

    // eslint-disable-next-line no-unused-vars
    async getNotificationList({ commit }, { $skip = 0, $limit = 10, read, $project, category = 'inbox' }) {
        try {
            return apiService.get(`/alert-notification/${category}`,{ params: { $skip, $limit, read, $project }, baseURL: config.VUE_APP_API_USERS_URL  })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    readAll({ commit }, { category }) {
        return apiService.patch(`/alert-notification/${category}?read=false`,  { read: true }, { baseURL: config.VUE_APP_API_USERS_URL })
    },
    // eslint-disable-next-line no-unused-vars
    changeArchiveFlag({ commit }, { id, archive, read, category = 'inbox' }) {
        // const queryParams = Array.isArray(id) ? id.map(id => `id[]=${id}`).join('&') : `id[]=${id}`

        return apiService.patch(`/alert-notification/${category}/${id}`,  { archive, read }, { baseURL: config.VUE_APP_API_USERS_URL })
    },

    // eslint-disable-next-line no-unused-vars
    getSubscriptions({ commit }, { $project = 0 }) {
        return apiService.get(`/subscriptions`,  { params: { $project }, baseURL: config.VUE_APP_API_USERS_URL })
    },
}
