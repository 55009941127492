import apiService from '@/utils/api-client'
import store from '@/store'
import config from "@/utils/appConfig";
import appConfig from '@/utils/appConfig'
// Temp
// import { errorsMessageListForReportSouses } from '@/utils/errors';

export default {
    async getSource({ commit }, { address, cluster, directionUp, from = undefined, to = undefined }) {
        try {
            const data = apiService.get(`/explorer/getSource`, { params: { address, from, to, cluster, directionUp } })
            commit('ADD_HISTORY_ENTRY', { path: `/explorer/getSource`, params: { address, from, to } })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    async getSankeyData({ commit }, { address, cluster, directionUp, from = undefined, to = undefined }) {
        try {
            const data = apiService.get(`/explorer/sankey`, { params: { address, from, to, cluster, directionUp } })
            commit('ADD_HISTORY_ENTRY', { path: `/explorer/sankey`, params: { address, from, to } })
            return data
        } catch (error) {
            console.error(error)
        }
    },
  async getTransactionInfo({ commit }, {
      txHash,
      isAddress = false,
      only = undefined,
      sortByAddress = undefined,
      inputsPerPage = only ===  'outputs' ? 1000: 20,
      outputsPerPage = only ===  'inputs' ? 1000: 20,
      inputsSkip = 0,
      outputsSkip = 0
  }) {
    try {
      const data = apiService.get(`explorer/txBrowserIO?${isAddress ? 'address' : 'tx_hash'}=${ txHash }`, { params: {
          only,
          // isAddress,
          inputsPerPage,
          outputsPerPage,
          inputsSkip,
          outputsSkip,
          sortByAddress,
      } })
      commit('ADD_HISTORY_ENTRY', { path: `api/explorer/txBrowserIO/${ txHash }`, params: { txHash } })
      return data
    } catch (error) {
      console.error(error)
    }
  },
    async getTransactionTokens({ commit }, { tx, supported = true }) {
        try {
            const data = apiService.get(`explorer/getTransactionTokens/${ tx }`, { params: { supported: supported || undefined } })
            commit('ADD_HISTORY_ENTRY', { path: `api/explorer/getTransactionTokens/${ tx }` })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    async getAddressTokens({ commit }, { address, supported = true }) {
        try {
            const data = apiService.get(`explorer/getAddressTokens/${ address }`, { params: { supported: supported || undefined, $limit: supported ? 1000 : undefined } })
            commit('ADD_HISTORY_ENTRY', { path: `api/explorer/getAddressTokens/${ address }` })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    async getTokens({ commit }, { supported = true, token }) {
        try {
            const data = apiService.get(`token`, { params: { token, supported: supported || undefined, $limit: supported ? 1000 : undefined } })
            commit('ADD_HISTORY_ENTRY', { path: `token` })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    async getAddressBalance({ commit }, { address, contract }) {
        try {
            const data = apiService.get(`explorer/getBalance/${ address }`, { params: {
                contract: contract === 'all' ? undefined : contract,
            }})
            commit('ADD_HISTORY_ENTRY', { path: `api/explorer/getBalance/${ address }` })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    async getTxEthData({ commit }, {
        tx,
        currentDate = undefined,
        contract = undefined,
        startRangeDate = undefined,
        endRangeDate = undefined,
        sort = undefined,
        limit = undefined,
        page = undefined,
        only = undefined,
        address,
    }) {
        try {
            const data = await apiService.get(`explorer/txBrowserIO`, { params: {
                    tx_hash: tx,
                    startRangeDate,
                    endRangeDate,
                    contract,
                    currentDate,
                    sort,
                    limit,
                    page,
                    only,
                    address,
                }})
            commit('ADD_HISTORY_ENTRY', { path: `explorer/txBrowserIO/${ tx }`, params: { tx} })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    async getTxEthScore({ commit }, {
        tx,
        token,
        tokenTicker
    }) {
        try {
            const data = await apiService.get(`calculation/getTxScore/${ tx }`, { params: {
                token: token || undefined,
                tokenTicker,
            }})
            commit('ADD_HISTORY_ENTRY', { path: `calculation/getTxScore/${ tx }`, params: { tx } })
            return data
        } catch (error) {
            console.error(error)
        } finally {
          await store.dispatch('user/getMe')
        }
    },
  async getAddressEthScore({ commit } ,{
    address,
    token,
    tokenTicker,
  }) {
    try {
      const data = await apiService.get(`calculation/getAddressScore/${ address }`, { params: {
          token: token || undefined,
          tokenTicker,
        }})
      commit('ADD_HISTORY_ENTRY', { path: `calculation/getAddressScore/${ address }`, params: { token } })
      return data
    } catch (error) {
      console.error(error)
      return error.response
    } finally {
      await store.dispatch('user/getMe')
    }
  },
    async getEthAddressData({ commit }, {
        address,
        contract = undefined,
        startRangeDate = undefined,
        endRangeDate = undefined,
        only = undefined,
        prioritize = undefined,
        minAmount = undefined,
        maxAmount = undefined,
        page = 1,
        count = 10,
        sort = -1,
        type,
        direction = 2,
    }) {
        try {
            const data = await apiService.get(`explorer/getAddressBrowser/${ address }`, { params: {
                    startRangeDate: startRangeDate || undefined,
                    endRangeDate: endRangeDate || undefined,
                    prioritize,
                    minAmount,
                    maxAmount,
                    contract,
                    page,
                    count,
                    sort,
                    only,
                    type,
                    direction,
                }})
            commit('ADD_HISTORY_ENTRY', { path: `explorer/getAddressBrowser/${ address }`, params: { address } })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    async getTransactionInfoIO({ commit }, { txHash, only = undefined, inputsPerPage = 10, outputsPerPage = 10, inputsSkip = 0, outputsSkip = 0 }) {
    try {
      const data = apiService.get(`explorer/txBrowserIO/${ txHash }`, { params: {
          only,
          inputsPerPage,
          outputsPerPage,
          inputsSkip,
          outputsSkip,
      } })
      commit('ADD_HISTORY_ENTRY', { path: `api/explorer/txBrowserIO/${ txHash }`, params: { txHash } })
      return data
    } catch (error) {
      console.error(error)
    }
  },
    async getAddressBrowserDetails({ commit }, { tx, inputsPerPage = 10, outputsPerPage = 10, inputsSkip = 0, outputsSkip = 0, address, hash }) {
        try {
            const data = await apiService.get(`explorer/addressBrowserDetails`, { params: {
                inputsPerPage,
                outputsPerPage,
                inputsSkip,
                outputsSkip,
                address,
                hash,
            } })
            commit('ADD_HISTORY_ENTRY', { path: `api/explorer/addressBrowserDetails`, params: { tx } })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    async getAddressBrowserList({ commit }, {
        address,
        currentDate = undefined,
        startRangeDate = undefined,
        endRangeDate = undefined,
        sort = undefined,
        page = 1,
        count = 5,
        minAmount = undefined,
        maxAmount = undefined,
        direction = 2,
    }) {
        try {
            const data = await apiService.get(`explorer/getShortTxsListByAddress/${ address }`, { params: {
                    startRangeDate: startRangeDate || undefined,
                    endRangeDate: endRangeDate || undefined,
                    currentDate: currentDate || undefined,
                    sort: sort || undefined,
                    page,
                    count,
                    minAmount,
                    maxAmount,
                    direction,
                }})
            commit('ADD_HISTORY_ENTRY', { path: `api/explorer/getShortTxsListByAddress/${ address }`, params: { address} })
            return data
        } catch (error) {
            console.error(error)
        }
    },
  async getNewAddressInfo({ commit }, {
    address,
    currentDate = undefined,
    page = 1,
    count = 5,
    startRangeDate = undefined,
    endRangeDate = undefined,
    sort = undefined,
  }) {
    try {
      const data = await apiService.get(`explorer/getAddressBrowser/${ address }`, { params: {
          startRangeDate,
          endRangeDate,
          currentDate,
          sort,
          page,
          count,
      }})
      commit('ADD_HISTORY_ENTRY', { path: `api/explorer/getAddressBrowser/${ address }`, params: { address} })
      return data
    } catch (error) {
      console.error(error)
    }
  },
	async getTransactionData({ commit }, address) {
		try {
			const data = apiService.get(`explorer/getAddressTxsStats/${ address }`)
			commit('ADD_HISTORY_ENTRY', { path: `api/explorer/getTransactionInfo/${ address }`, params: { address } })
			return data
		} catch (error) {
			console.error(error)
		}
	},
    async getAddressHistory({ commit }, { address, page }) {
		try {
			const data = apiService.get(`labeling/getAddressHistory/${ address }`, { params: { page } })
			commit('ADD_HISTORY_ENTRY', { path: `api/tx/getTransactionInfo/${ address }`, params: { address } })
			return data
		} catch (error) {
			console.error(error)
		}
	},
    async getAddressMetrics({ commit }, { startRangeDate, endRangeDate, address, contract = '', type = 'main' }) {
		try {
			const data = apiService.get(`explorer/addressMetrics/${ store.state.analytics.coinData.key === 'trx' ? address : address.toLowerCase() }`, { params: {
                contract: contract === 'all' ? '' : contract,
                type,
                startRangeDate: startRangeDate || undefined,
                endRangeDate: endRangeDate || undefined
            } })
			commit('ADD_HISTORY_ENTRY', { path: `api/explorer/addressMetrics/${ address }`})
			return data
		} catch (error) {
			console.error(error)
		}
	},
    async getClusterHistory({ commit }, { cluster, page }) {
		try {
			const data = apiService.get(`labeling/getClusterHistory/${ cluster }`, { params: { page } })
			commit('ADD_HISTORY_ENTRY', { path: `api/labeling/getTransactionInfo/${ cluster }`, params: { cluster } })
			return data
		} catch (error) {
			console.error(error)
		}
	},
    async getClusterStat({ commit }, cluster) {
		try {
			const data = apiService.get(`labeling/getClusterStatistics/${ cluster }`)
			commit('ADD_HISTORY_ENTRY', { path: `api/labeling/getClusterStatistics/${ cluster }` })
			return data
		} catch (error) {
			console.error(error)
		}
	},
	async getTxStats({ commit }, txHash) {
		try {
			const data = apiService.get(`labeling/getTxStats/${ txHash }`)
			commit('ADD_HISTORY_ENTRY', { path: `api/labeling/getTxStats/${ txHash }`, params: { txHash } })
			return data
		} catch (error) {
			console.error(error)
		}
	},
	async getAddressData({ commit }, { address, timestamp = undefined }) {
    try {
      const data = await apiService.get(`explorer/getAddressInfo/${ address }`, { params: { timestamp }})
      commit('ADD_HISTORY_ENTRY', { path: `api/explorer/getAddressInfo/${ address }`, params: { address } })
      return data
    } catch (error) {
      console.error(error)
    }
  },
    async getEthAddressInfo({ commit }, address) {
    try {
      const data = await apiService.get(`explorer/getAddressInfo/${ address }`)
      commit('ADD_HISTORY_ENTRY', { path: `api/explorer/getAddressInfo/${ address }`, params: { address } })
      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getAddressUOF({ commit }, {
    address,
    directionUp = config.VUE_APP_SCORE_REQUEST_DIRECTION,
    token,
    tokenTicker,
  }) {
    try {
      const data = await apiService.get(`calculation/getAddressScoreV2/${ address }`, { params: { direction: directionUp, token: token === 'all' ? true : token, tokenTicker  } })
      commit('ADD_HISTORY_ENTRY', { path: `api/calculation/getAddressScoreV2/${ address }`, params: { address } })
      return data
    } catch (error) {
      console.error(error)
      return error.response
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getTxUOF({ commit }, {
    tx_hash,
    directionUp = config.VUE_APP_SCORE_REQUEST_DIRECTION,
    token,
    tokenTicker
  }) {
    try {
      const data = await apiService.get(`calculation/getTxScoreV2/${ tx_hash }`, { params: { direction: directionUp, token: token || undefined, tokenTicker  } })
      commit('ADD_HISTORY_ENTRY', { path: `api/calculation/getTxScoreV2/${ tx_hash }`, params: { tx_hash } })
      return data
    } catch (error) {
      console.error(error)
      return error.response
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getReportAddressAgg({ commit }, {
    address,
    direction = config.VUE_APP_SCORE_REQUEST_DIRECTION,
    token = config.VUE_APP_DEFAULT_TOKEN,
    tokenTicker,
    totalFunds,
    isMonitoring
  }) {
    try {
      const data = await apiService.get(`report/address/${ address }/agg`, { params: { direction, isMonitoring, token: token === 'all' ? true : token, tokenTicker, $project: { totalFunds } } })
      commit('ADD_HISTORY_ENTRY', { path: `api/report/address/${ address }/agg`, params: { address } })
      return data
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getReportTxAgg({ commit }, {
    tx_hash,
    directionUp = config.VUE_APP_SCORE_REQUEST_DIRECTION,
    token,
    tokenTicker,
    totalFunds
  }) {
    try {
      const data = await apiService.get(`report/tx_hash/${ tx_hash }/agg`, { params: { direction: directionUp, token: token || undefined, tokenTicker, $project: { totalFunds } } })
      commit('ADD_HISTORY_ENTRY', { path: `api/report/tx_hash/${ tx_hash }/agg`, params: { tx_hash } })
      return data
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getReportAddress({ commit }, {
    address,
    direction = config.VUE_APP_SCORE_REQUEST_DIRECTION,
    token,
    tokenTicker,
    totalFunds,
    alerts,
    isMonitoring,
    shallow = config.VUE_APP_SHALLOW,
    ...extraParams
  }) {
    try {
      const data = await apiService.get(`report/address/${ address }`, { params: { ...extraParams, shallow, direction, isMonitoring, token: token === 'all' || token === undefined ? 'supported' : token, tokenTicker, $project: { totalFunds, alerts } } })
      commit('ADD_HISTORY_ENTRY', { path: `api/report/address/${ address }`, params: { address } })
      return data
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getReportTx({ commit }, {
    tx_hash,
    directionUp = config.VUE_APP_SCORE_REQUEST_DIRECTION,
    token,
    tokenTicker,
    totalFunds,
    ...extraParams
  }) {
    try {
      const data = await apiService.get(`report/tx_hash/${ tx_hash }`, { params: { ...extraParams, direction: directionUp, token: token || '', tokenTicker, $project: { totalFunds } } })
      commit('ADD_HISTORY_ENTRY', { path: `api/report/tx_hash/${ tx_hash }`, params: { tx_hash } })
      return data
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getAddressRiskReport({ commit }, {
    address,
    directionUp = false,
    token,
    tokenTicker,
  }) {
      try {
          const data = await apiService.get(`calculation/getAddressScore/${ address }`, { params: { directionUp, token: token || undefined, tokenTicker  } })
          commit('ADD_HISTORY_ENTRY', { path: `api/calculation/getAddressScore/${ address }`, params: { address } })
          return data
      } catch (error) {
          console.error(error)
      } finally {
        await store.dispatch('user/getMe')
      }
  },
  async getTxRiskReport({ commit }, {
    tx_hash,
    directionUp = false,
    token,
    tokenTicker
  }) {
    try {
      const data = await apiService.get(`calculation/getTxScore/${ tx_hash }`, { params: { directionUp, token: token || undefined, tokenTicker } })
      commit('ADD_HISTORY_ENTRY', { path: `api/calculation/getAddressScore/${ tx_hash }`, params: { tx_hash } })
      return data
    } catch (error) {
      console.error(error)
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getTransactionRisk({ commit }, txHash) {
    try {
      const data = await apiService.get(`calculation/getTxScore/${ txHash }`)
      commit('ADD_HISTORY_ENTRY', { path: `api/calculation/getTxScore/${ txHash }`, params: { txHash } })
      return data
    } catch (error) {
      console.error(error)
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getAddressRisk({ commit }, address) {
    try {
      const data = await apiService.get(`calculation/getAddressScore/${ address }`, { params: {  } })
      commit('ADD_HISTORY_ENTRY', { path: `api/calculation/getAddressScore/${ address }`, params: { address } })
      return data
    } catch (error) {
      console.error(error)
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  async getTxIntersection({ commit }, value) {
    try {
        const data = await apiService.get(`calculation/getTxsCross?${value ? value.map((n) => `txsArray=${n}`).join('&') : ''}`)
        commit('ADD_HISTORY_ENTRY', { path: `api/calculation/getTxsCross/${ value }`, params: { value } })
        return data
    } catch (error) {
        console.error(error)
    }
  },
  async getHistory({ commit }, count = 20) {
    const data = await apiService.get('tx/history', { params: { count } })
    commit('SET_HISTORY', data)
  },
  async checkSources({ commit }, { outTxHash, sourceTxHash }) {
    try {
      const data = await apiService.get(`tx/checkSources/${ outTxHash }/${ sourceTxHash }`)
      commit('ADD_HISTORY_ENTRY', {
        path: `api/tx/checkSources/${ outTxHash }/${ sourceTxHash }`,
        params: { hashes: [outTxHash, sourceTxHash] },
      })
      return data
    } catch (error) {
      console.error(error)
    }
  },
  async calculationAddressRisk({ commit }, address) {
    try {
      const data = await apiService.get(`calculation/getAddressScore/${ address }`)
      commit('ADD_HISTORY_ENTRY', { path: `api/calculation/getAddressScore/${ address }`, params: { address } })
      return data
    } catch (error) {
      console.error(error)
    } finally {
      await store.dispatch('user/getMe')
    }
  },

    // eslint-disable-next-line no-unused-vars
    async getAddressTags({ commit, state }, { address, count = 5, skip = 0 }) {
        try {
            const data = apiService.get(`labeling/getAddressTagsList/${address}`, { params: { address, count, skip  } })
            return data
        } catch (error) {
            console.error(error)
        }
    },

    // eslint-disable-next-line no-unused-vars
    async getClusterTags({ commit }, { cluster, count = 5, skip = 0 }) {
        try {
            const data = apiService.get(`labeling/clusterTagsList/${cluster}`, { params: { cluster, count, skip } })
            return data
        } catch (error) {
            console.error(error)
        }
    },

    // eslint-disable-next-line no-unused-vars
    async getClusterAddresses({ commit }, { cluster, limit = 5, skip = 0, sortField, sortDirection }) {
        try {
            const data = apiService.get(`labeling/getClusterAddresses/${cluster}`, { params: { cluster, limit, skip, sortField, sortDirection } })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getRiskAddressHistoryList({ commit }, { count = 5, skip = 0 }) {
        try {
            const data = apiService.get(`history/getAddressHistoryRiskList`, { params: { count, skip, ticker: store.state.analytics.coinData.key }, baseURL: config.VUE_APP_API_USERS_URL })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getRiskTxHistoryList({ commit }, { count = 5, skip = 0 }) {
        try {
            const data = apiService.get(`history/getTxRiskHistoryList`, { params: { count, skip, ticker: store.state.analytics.coinData.key }, baseURL: config.VUE_APP_API_USERS_URL })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getTxAMLInfo({ commit }, { tx_hash }) {
        try {
            const data = apiService.get(`explorer/txAMLInfo/${tx_hash}`)
            return data
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getCurrencyInfo({ commit }, { address, $project = undefined }) {
        const sendAddresses = Array.isArray(address) ? address : [address]
        try {
            return apiService.post('/explorer/getCurrencyInfo', { address: sendAddresses, $project })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getPrevNextData({ commit }, { nextTx, prevTx, sortByFromTo, page = 1, limit = 20 }) {
        try {
            return apiService.post('/explorer/addressPrevNext', { nextTx, prevTx, page, limit, sortByFromTo })
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getFilterTracedTxs({ commit }, { nextTx, prevTx }) {
        try {
            const data = apiService.post('/explorer/filterTracedTxs', { nextTx, prevTx })
            return data
        } catch (error) {
            console.error(error)
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getTransactionActions({ commit }, { tx }) {
        try {
            const data = apiService.get(`explorer/getTransactionActions/${tx}`)
            return data
        } catch (error) {
            console.error(error)
        }
    },

    async getEssentialRiskReport({ commit }, {
      hash,
      blockchain,
    }) {
      try {
        const path = `/getScore/${ hash }/${blockchain.toLowerCase()}`
        const data = await apiService.get(path)
        commit('ADD_HISTORY_ENTRY', { path: `api/getScore/${ hash }`, params: { hash } })
        return data
      } finally {
        await store.dispatch('user/getMe')
      }
    },
    async getEssentialBlockchains({ commit }) {
      try {
        const path = `/blockchains`
        const data = await apiService.get(path, { baseURL: appConfig.VUE_APP_ESSENTIAL_API_URL })
        commit('ADD_HISTORY_ENTRY', { path: `api/blockchains` })
        return data
      } catch (error) {
        console.error(error)
      } finally {
        await store.dispatch('user/getMe')
      }
    },

}
